@import 'icon-variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?p73nza');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?p73nza#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?p73nza') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?p73nza') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?p73nza##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-alert {
  &:before {
    content: $icon-icon-alert;
  }
}

.icon-shape {
  &:before {
    content: $icon-shape;
  }
}
.icon-icon-mail {
  &:before {
    content: $icon-icon-mail; 
  }
}
.icon-icon-in-breve-assegnazione-in-distacco {
  &:before {
    content: $icon-icon-in-breve-assegnazione-in-distacco; 
  }
}
.icon-icon-in-breve-contratto {
  &:before {
    content: $icon-icon-in-breve-contratto; 
  }
}
.icon-icon-in-breve-formazione-obbligatoria {
  &:before {
    content: $icon-icon-in-breve-formazione-obbligatoria; 
  }
}
.icon-icon-in-breve-provvedimenti-disciplinari-negli-ultimi-due-anni {
  &:before {
    content: $icon-icon-in-breve-provvedimenti-disciplinari-negli-ultimi-due-anni; 
  }
}
.icon-icon-in-breve-richiesta-trasferimento-attivo {
  &:before {
    content: $icon-icon-in-breve-richiesta-trasferimento-attivo; 
  }
}
.icon-icon-in-breve-ulteriore-multi-incarico-attivo {
  &:before {
    content: $icon-icon-in-breve-ulteriore-multi-incarico-attivo; 
  }
}
.icon-icon-down {
  &:before {
    content: $icon-icon-down; 
  }
}
.icon-mappamondo {
  &:before {
    content: $icon-mappamondo; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-assistance-hr {
  &:before {
    content: $icon-assistance-hr; 
  }
}
.icon-people {
  &:before {
    content: $icon-people;     
    color: #fff;
  }
}
.icon-completato {
  &:before {
    content: $icon-completato; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-commenti {
  &:before {
    content: $icon-commenti;     
    color: #989898;
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-right-negativo {
  &:before {
    content: $icon-right-negativo;     
    color: #fff;
  }
}
.icon-right {
  &:before {
    content: $icon-right; 
  }
}
.icon-torna-su {
  &:before {
    content: $icon-torna-su;     
    color: #fff;
  }
}
.icon-document {
  &:before {
    content: $icon-document;     
    color: #fff;
  }
}
.icon-group {
  &:before {
    content: $icon-group;     
    color: #fff;
  }
}
.icon-info {
  &:before {
    content: $icon-info;     
    color: #d8d8d8;
  }
}
.icon-like-white {
  &:before {
    content: $icon-like-white;     
    color: #fff;
  }
}
.icon-mipiace {
  &:before {
    content: $icon-mipiace;     
    color: #989898;
  }
}
.icon-notifiche {
  &:before {
    content: $icon-notifiche;     
    
  }
}
.icon-profilo {
  &:before {
    content: $icon-profilo;     
    color: #fff;
  }
}
.icon-search {
  &:before {
    content: $icon-search;     
    color: #fff;
  }
}
.icon-select-negativo {
  &:before {
    content: $icon-select-negativo;     
    color: #fff;
  }
}
.icon-share {
  &:before {
    content: $icon-share;     
    color: #fff;
  }
}
.icon-video .path1 {
  &:before {
    content: $icon-video-path1;  
    color: rgb(255, 255, 255);  
    opacity: 0.4;
  }
}
.icon-video .path2 {
  &:before {
    content: $icon-video-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-visualizzazioni {
  &:before {
    content: $icon-visualizzazioni;     
    color: #979797;
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-badge {
  &:before {
    content: $icon-badge; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-corsi {
  &:before {
    content: $icon-corsi; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-decrease {
  &:before {
    content: $icon-decrease; 
  }
}
.icon-discussioni {
  &:before {
    content: $icon-discussioni; 
  }
}
.icon-grow {
  &:before {
    content: $icon-grow; 
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-info1 {
  &:before {
    content: $icon-info1; 
  }
}
.icon-locked {
  &:before {
    content: $icon-locked; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-nonutile {
  &:before {
    content: $icon-nonutile; 
  }
}
.icon-punteggio-alto {
  &:before {
    content: $icon-punteggio-alto; 
  }
}
.icon-punteggio-basso {
  &:before {
    content: $icon-punteggio-basso; 
  }
}
.icon-punteggio-medio-1 {
  &:before {
    content: $icon-punteggio-medio-1; 
  }
}
.icon-search1 {
  &:before {
    content: $icon-search1; 
  }
}
.icon-totalearticoli {
  &:before {
    content: $icon-totalearticoli; 
  }
}
.icon-like {
  &:before {
    content: $icon-like; 
  }
}
.icon-left {
  &:before {
    content: $icon-left; 
  }
}
.icon-logo {
  &:before {
    content: $icon-logo; 
  }
}
.icon-star-full {
  &:before {
    content: $icon-star-full; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-preferiti {
  &:before {
    content: $icon-preferiti; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-finanzia {
  &:before {
    content: $icon-finanzia; 
  }
}
.icon-isp-logo-2 {
  &:before {
    content: $icon-isp-logo-2; 
  }
}
.icon-isp-logo {
  &:before {
    content: $icon-isp-logo; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-bell-empty {
  &:before {
    content: $icon-bell-empty; 
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty; 
  }
}
.icon-bell-full {
  &:before {
    content: $icon-bell-full; 
  }
}
.icon-heart-empty {
  &:before {
    content: $icon-heart-empty; 
  }
}
.icon-discover {
  &:before {
    content: $icon-discover; 
  }
}
.icon-icon-video {
  &:before {
    content: $icon-icon-video; 
  }
}

