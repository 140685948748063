@import "structure/bootstrap";
@import "structure/base";
@import "structure/reset";
//@import "structure/fonts";
@import "structure/icon-style";
//@import "structure/common";

$font-base-url: '/assets/fonts/';

/* Normal */
@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  src: url($font-base-url + 'Roboto-Regular.ttf'), format('truetype');
}

/* Italic */
@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: italic;
  src: url($font-base-url + 'Roboto-Italic.ttf'), format('truetype');
}

/* Light */
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url($font-base-url + 'Roboto-Light.ttf'), format('truetype');
}

/* Medium */
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url($font-base-url + 'Roboto-Medium.ttf'), format('truetype');
}

/* Bold */
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url($font-base-url + 'Roboto-Bold.ttf'), format('truetype');
}

/* Black */
@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  src: url($font-base-url + 'Roboto-Black.ttf'), format('truetype');
}

@font-face {
  font-family: 'Intro';
  font-weight: 900;
  font-style: normal;
  src: url($font-base-url + 'Intro-Black.ttf'), format('truetype');
}

body {
  background-color: #2c2d32;
  color: #fff;
  font-family: Arial, sans-serif;
}
