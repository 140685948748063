$icomoon-font-family: "s4b" !default;
$icomoon-font-path: "../../fonts" !default;

$icon-icon-alert: "\e945";
$icon-shape: "\e944";
$icon-icon-mail: "\e943";
$icon-icon-in-breve-assegnazione-in-distacco: "\e93d";
$icon-icon-in-breve-contratto: "\e93e";
$icon-icon-in-breve-formazione-obbligatoria: "\e93f";
$icon-icon-in-breve-provvedimenti-disciplinari-negli-ultimi-due-anni: "\e940";
$icon-icon-in-breve-richiesta-trasferimento-attivo: "\e941";
$icon-icon-in-breve-ulteriore-multi-incarico-attivo: "\e942";
$icon-icon-down: "\e93c";
$icon-mappamondo: "\e93b";
$icon-delete: "\e93a";
$icon-assistance-hr: "\e939";
$icon-people: "\e938";
$icon-completato: "\e937";
$icon-close: "\e936";
$icon-commenti: "\e935";
$icon-download: "\e934";
$icon-right-negativo: "\e931";
$icon-right: "\e932";
$icon-torna-su: "\e933";
$icon-document: "\e930";
$icon-group: "\e903";
$icon-info: "\e908";
$icon-like-white: "\e90b";
$icon-mipiace: "\e90e";
$icon-notifiche: "\e920";
$icon-profilo: "\e927";
$icon-search: "\e92a";
$icon-select-negativo: "\e92b";
$icon-share: "\e92c";
$icon-video-path1: "\e92d";
$icon-video-path2: "\e92e";
$icon-visualizzazioni: "\e92f";
$icon-home: "\e901";
$icon-badge: "\e900";
$icon-calendar: "\e906";
$icon-clock: "\e909";
$icon-corsi: "\e90f";
$icon-dashboard: "\e914";
$icon-decrease: "\e915";
$icon-discussioni: "\e916";
$icon-grow: "\e917";
$icon-help: "\e918";
$icon-info1: "\e919";
$icon-locked: "\e91a";
$icon-logout: "\e91b";
$icon-nonutile: "\e91c";
$icon-punteggio-alto: "\e91d";
$icon-punteggio-basso: "\e91e";
$icon-punteggio-medio-1: "\e91f";
$icon-search1: "\e921";
$icon-totalearticoli: "\e922";
$icon-like: "\e923";
$icon-left: "\e924";
$icon-logo: "\e925";
$icon-star-full: "\e926";
$icon-play: "\e928";
$icon-arrow-right: "\e929";
$icon-preferiti: "\e913";
$icon-check: "\e902";
$icon-finanzia: "\e8da";
$icon-isp-logo-2: "\e904";
$icon-isp-logo: "\e905";
$icon-pencil: "\e907";
$icon-bell-empty: "\e90a";
$icon-star-empty: "\e90c";
$icon-bell-full: "\e90d";
$icon-heart-empty: "\e910";
$icon-discover: "\e911";
$icon-icon-video: "\e912";

