* {
  &::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.container-fluid {
  margin: 0 auto;

  &.content-wrapper {
    padding: 0;
  }
}
